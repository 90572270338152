import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router'

import Loading from './Loading.vue'
import NotFound from './NotFound.vue'

const routes = [
    {
        path: '/preference/:id',
        beforeEnter: (to, from) => {
            if (!to.params.id.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)) {
                return '/'
            }
        },
        component: Loading,
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


const app = createApp({template: '<router-view />'})
app.use(router)
app.mount('#app')

