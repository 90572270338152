<template>
  <div class="methods shadow">
    <h2>¿Cómo quieres pagar?</h2>
    <MethodButton
      provider="tigomoney"
      v-model:fetching="fetching"
      v-model:messageAlertaData="messageAlertaData"
      v-model:mostrandoAlertaData="mostrandoAlertaData"
    />
    <MethodButton
      provider="personal"
      v-model:fetching="fetching"
      v-model:messageAlertaData="messageAlertaData"
      v-model:mostrandoAlertaData="mostrandoAlertaData"
    />
    <MethodButton
      provider="paypal"
      v-model:fetching="fetching"
      v-model:messageAlertaData="messageAlertaData"
      v-model:mostrandoAlertaData="mostrandoAlertaData"
    />
  </div>
</template>

<script>
import MethodButton from "./MethodButton.vue";

export default {
  name: "Methods",
  data() {
    return {
      fetching: false,
      messageAlertaData: "Error",
      mostrandoAlertaData: false,
    };
  },
  props: {
    messageAlerta: {
      type: String,
      default: "Error",
    },
    mostrandoAlerta: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MethodButton,
  },
  watch: {
    mostrandoAlertaData() {
      if (this.mostrandoAlertaData) {
        console.log(this.messageAlertaData);
        this.$emit("update:messageAlerta", this.messageAlertaData);
        this.$emit("update:mostrandoAlerta", true);
        this.mostrandoAlertaData = false;
      }
    },
  },
};
</script>

<style scoped>
.methods {
  flex: 3;
}
</style>