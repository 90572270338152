<template>
    <nav class="shadow">
        <div class="navbar">
            <p class="brand"><img src="@/assets/gwb.png" alt="geekWalletBrand" srcset="" rel="preload">
                <span>Geek-Wallet</span>
            </p>
        </div>
    </nav>
</template>

<style scoped>
nav {
    background-color: #FFF;
    font-display: flex;
    align-items: center;
    padding: 0.7rem 2rem;
}

.brand {
    display: flex;
    align-items:center;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
}

.brand img {
    display: inline-block;
    line-height: inherit;
    font-size: 1.4rem;
    height: 2.6rem;
    margin-right: 0.5rem;
}
</style>