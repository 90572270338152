<template>
  <div v-if="loading" class="total_center">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
  <App v-if="preference_loaded" :preference="preference" />
  <NotFound v-if="error" />
</template>

<script>
import App from "./App.vue";
import NotFound from "./NotFound.vue";

export default {
  name: "Loading",
  components: {
    App,
    NotFound,
  },
  data() {
    return {
      loading: true,
      preference_loaded: false,
      error: false,
      preference: {},
    };
  },
  methods: {
    async fetchPreference() {
      const res = await fetch(
        process.env.VUE_APP_APIURL + this.$route.params.id
      ).catch(() => {
        this.loading = false;
        this.error = true;
      });

      if (!this.error) {
        const data = await res.json();

        this.loading = false;

        if (data.preference_id !== this.$route.params.id) {
          this.error = true;
          return false;
        }

        this.preference_loaded = true;
        return data;
      }
    },
  },
  async created() {
    this.preference = await this.fetchPreference();
  },
};
</script>

<style>
</style>