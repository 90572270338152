<template>
  <div class="details shadow">
    <h2>Detalles de la compra</h2>
    <ul class="items">
      <li v-for="item in items" :key="item">
        {{ item.title }}
        <span v-if="item.quantity-1" class="quantity">
          x{{ item.quantity }}
        </span>
      </li>
    </ul>
    <p>
      Total: Gs. <span class="price">{{ total_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    items: Array,
    total_price: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.details {
  flex: 2;
}

.quantity {
  color: #999;
  font-size: 0.9rem;
}

.price {
  font-weight: bold;
  font-size: 1.2rem;
}
</style>