<template>
  <div><strong>Ocurrió un error: </strong>{{ message }}</div>
</template>

<script>
export default {
  props: {
    message: {
      default: "Error desconocido",
      type: String,
    },
  },
  setup() {},
};
</script>

<style scoped>
div {
  background-color: rgb(194, 69, 69);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  color: white;
  text-align: center;
  padding: 2rem;
  box-sizing: border-box;
}
</style>