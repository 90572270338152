<template>
  <div
    :id="provider"
    class="method-button"
    :class="{ disabled: fetching || error, animation: !fetching && !error }"
    @click="fetchPayment(provider)"
  >
    <div class="method-text">Pagá con</div>
    <img
      class="method-brand"
      :src="getLogo(provider)"
      alt="Pagar con Billetera Personal"
      srcset=""
      rel="preload"
    />
  </div>
</template>

<script>
export default {
  name: "MethodButton",
  props: {
    provider: String,
    fetching: Boolean,
    mostrandoAlertaData: Boolean,
  },
  data() {
    return {
      active: true,
      error: false,
      payment_loaded: false,
    };
  },
  methods: {
    getLogo(prov) {
      return "../assets/logo-" + prov + ".png";
    },
    async fetchPayment(prov) {
      if (this.active && !this.fetching && !this.error) {
        this.$emit("update:fetching", true);

        const res = await fetch(
          `${process.env.VUE_APP_APIURL}${prov}/${this.$route.params.id}`,
          {
            method: "POST",
          }
        ).catch(() => {
          this.mostrarAlerta("Intenta más tarde o con otro método de pago");
        });

        if (this.error) {
          return false;
        }

        const data = await res.json();
        if (data.preference_id === this.$route.params.id) {
          window.location.href = data.url
          return true;
        }

        this.mostrarAlerta("Intenta más tarde o con otro método de pago");
        return false;
      }
    },
    mostrarAlerta(msg) {
      this.error = true;
      this.$emit("update:fetching", false);
      this.$emit("update:messageAlertaData", msg);
      this.$emit("update:mostrandoAlertaData", true);
    },
  },
};
</script>

<style scoped>
.method-button {
  color: #fff;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.7rem;
  box-sizing: border-box;
  border: 0px solid;
  border-radius: 0.4rem;
  cursor: pointer;
  height: 5rem;
  margin-top: 2rem;
  transition: all 0.2s ease-in-out;
}

.animation:hover {
  transform: scale(1.02);
}

.method-brand {
  display: inline-block;
  line-height: inherit;
  max-width: 8rem;
  height: auto;
}

#personal {
  background-color: #01baf1;
}

#personal .disabled {
  background-color: #a11717;
}

#tigomoney {
  background-color: #00367b;
}

#tigomoney .method-brand {
  max-width: 9rem;
}

#paypal {
  color: #162c6f;
  border: 2px solid #162c6f;
}

.disabled {
  opacity: 0.7;
}

@media (max-width: 800px) {
  .method-button {
    font-size: 1.2rem;
  }

  .method-brand {
    max-width: 5rem;
  }

  #tigomoney .method-brand {
    max-width: 5.5rem;
  }
}
</style>