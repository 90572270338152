<template>
  <div id="back">
    <a href="https://geek-wallet.com/d/ceneka"> &#8592; Regresar</a>
  </div>
</template>

<style scoped>
#back {
  text-align: center;
  margin: 2rem auto;
}

#back a {
  color: #999;
  text-decoration: none;
  font-size: 0.8rem;
}
</style>