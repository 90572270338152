<template>
    <div class="total_center">
        <p>404</p>
        <p><strong>No se encontró nada por aquí</strong></p>
    </div>
</template>

<style scoped>
div {
    color: #999;
    font-size: 1.4rem;
    text-align: center;
    padding: 2rem;
    box-sizing: border-box;
    flex-direction: column;
}
</style>