<template>
  <transition name="alert">
    <Alert
      v-if="mostrandoAlerta"
      :message="messageAlerta"
      :mostrando="mostrandoAlerta"
    />
  </transition>
  <Navbar />
  <main>
    <h1>{{ preference.seller }}</h1>
    <div id="container">
      <Details
        :items="preference.items"
        :total_price="preference.total_price"
      />
      <Methods
        v-model:mostrandoAlerta="mostrandoAlerta"
        v-model:messageAlerta="messageAlerta"
      />
    </div>
    <Back />
  </main>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Details from "./components/Details.vue";
import Methods from "./components/methods/Methods.vue";
import Back from "./components/Back.vue";
import Alert from "./components/Alert.vue";

export default {
  name: "App",
  data() {
    return {
      messageAlerta: "Error",
      mostrandoAlerta: false,
    };
  },
  components: {
    Navbar,
    Details,
    Methods,
    Back,
    Alert,
  },
  props: {
    preference: Object,
  },
  computed: {
    currentRouteName() {
      return this.$router.currentRoute._rawValue.params.id;
    },
  },
  methods: {
    countDownAlert() {
      setTimeout(() => {
        this.mostrandoAlerta = false;
      }, 8000);
    },
  },
  watch: {
    mostrandoAlerta() {
      if (this.mostrandoAlerta) {
        this.countDownAlert();
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,200");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #eeeeee;
  margin: 0;
  font-size: 16px;
}

.shadow {
  box-shadow: 0 0.2em 0.4em 0 rgb(0 0 0 / 10%);
}

nav .navbar,
main {
  max-width: 52rem;
  margin: 0 auto;
  box-sizing: border-box;
}

#container {
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
  align-items: flex-start;
}

#container > div {
  background-color: #fff;
  padding: 2rem;
}

@media (max-width: 800px) {
  #container {
    flex-direction: column;
    align-items: stretch;
    max-width: 32rem;
  }

  #container > div {
    padding: 1.2rem;
  }
}

h1 {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 1rem 0.5rem;
}

h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.1rem;
}
</style>
